@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');

@layer base {
    body{
        @apply font-[Montserrat];
    }

    li{
        @apply px-4;
        @apply cursor-pointer;
    }
    .button-animation:hover span {
        @apply rotate-90;
    }
}